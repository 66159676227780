import React, { useRef, useState } from 'react';
import './App.css';

import firebase from 'firebase/app';
import 'firebase/firestore';
import 'firebase/auth';
import 'firebase/analytics';
// import 'functions/index'

import { useAuthState } from 'react-firebase-hooks/auth';
import { useCollectionData } from 'react-firebase-hooks/firestore';

firebase.initializeApp({
    apiKey: "AIzaSyCXct-jXgsa4RDAV0oUpPjnqM7h_3OIVJY",
    authDomain: "devschat-ca40f.firebaseapp.com",
    projectId: "devschat-ca40f",
    storageBucket: "devschat-ca40f.appspot.com",
    messagingSenderId: "201336962418",
    appId: "1:201336962418:web:d4e4199da82880f0e131eb"
})

const auth = firebase.auth();
const firestore = firebase.firestore();


function App() {
  const [user] = useAuthState(auth);

  return ( 

    <div className="App">  
      <header>
    <h1 className="title">Developers4Ever<span className='tech'>.tech</span></h1>
    <Settings/>
    </header>  
    <section>
      {user ? <ChatRoom /> : <SignIn />}
    </section>

  </div>
  );
}

function SignIn() {
  const signInWithGoogle = () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    auth.signInWithPopup(provider)
  }

  return (
    <>
      <button className="sign-in" href="#" onClick={signInWithGoogle}><img 
      src='https://media.discordapp.net/attachments/971359170429333524/1048179226706198528/unknown.png'  alt='google'
     className='google' /> Sign in with Google </button>
    </>
  )

}

function SignOut() {
  return auth.currentUser && (
    <button className="sign-out" onClick={() => auth.signOut()}>Sign Out</button>
  )
}

function settingsmenu() {
  let popup = document.getElementById("popup")
  popup.showModal()
}


function Settings() {
  return auth.currentUser && ( <>
    <img alt='img' src={auth.currentUser.photoURL || 'https://images.nightcafe.studio//assets/profile.png?tr=w-1600,c-at_max'} className="settings" onClick={settingsmenu}></img>
    </>
  )
}


function ChatRoom() {
  const dummy = useRef();
  const messagesRef = firestore.collection('messages');
  const query = messagesRef.orderBy('createdAt').limit(25);
  const [messages] = useCollectionData(query, { idField: 'id' });

  const [formValue, setFormValue] = useState('');
  const sendMessage = async (e) => {
    e.preventDefault();
    const { uid, photoURL, displayName } = auth.currentUser || 'https://images.nightcafe.studio//assets/profile.png?tr=w-1600,c-at_max';
    await messagesRef.add({
      text: formValue,
      createdAt: firebase.firestore.FieldValue.serverTimestamp(),
      uid,
      photoURL,
      displayName
    })

    setFormValue('');
    dummy.current.scrollIntoView({ behavior: 'smooth' });
  }
  
  const purl = auth.currentUser.photoURL || 'https://images.nightcafe.studio//assets/profile.png?tr=w-1600,c-at_max'
  const dname = auth.currentUser.displayName || 'ERROR:GET'

  return ( <>

  <dialog id='popup' className="settingsmenu">
    <div className='i'>User Informations</div>
    <div className="closeb" id="closeb" onClick={close}><i class="fa-regular fa-circle-xmark"></i> <br/><span className='esc'>ESC</span></div>
  <img src={purl} alt=" system can't GET the img" className="userimg"></img>
 <div className="name">{dname}</div>
 <SignOut />
</dialog>
    <main>
      {messages && messages.map(msg => <ChatMessage key={msg.id} message={msg} />)}

      <span ref={dummy}></span>

    </main>


    <form onSubmit={sendMessage} className="form">
    <input autoComplete='off' id='inp' className='input' value={formValue} onChange={(e) => setFormValue(e.target.value)} placeholder="Message #general"></input>
    <button type="submit" id='subbtn' className='btn' disabled={!formValue || formValue.trim().length === 0}><i className="fa-solid fa-paper-plane"></i></button>
    </form>

  </>)
}

function close() {
  let vv = document.getElementById("popup")
  let xv =  document.getElementById("closeb")
  xv.onclick = () => {
    vv.close()
  } 
}



function ChatMessage(props) {
  const { text, uid, photoURL, displayName} = props.message;

  const messageclassName = uid === auth.currentUser.uid ? 'sent' : 'received';

  return (
  <>
    <div className={`message ${messageclassName}`}>
    <img className='img' src={photoURL || 'https://images.nightcafe.studio//assets/profile.png?tr=w-1600,c-at_max'} alt="IMG" />
      <p className='p'>
    <span className="displayname" id='msg'>{displayName}</span> 
      <br/>
      <span className="text">{text}</span>
      </p>
    </div>
  </>
  )

}


export default App;
